<template>
  <div>
    <p class="text-2xl mb-6">Pagos por conceptos</p>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'

// demos

export default {
  components: {},
  setup() {
    return {}
  },
}
</script>
